@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Josefin+Sans:wght@400;500;700&family=Montserrat:wght@200;400;600;900&family=Poppins:wght@300;400;500;700&display=swap);
.menu-sidedrawer .MuiPaper-root {
  height: calc(100%) !important;
  width: calc(100%) !important;
}

.menu-sidedrawer  .MuiPaper-root{
  background-color: rgb(117, 102, 133);
}

.menu-sidedrawer .MuiPaper-root .MuiBox-root{
  margin:0 auto; 
}

.menuIcon{
  color: #564181
}

.MuiDrawer-root .MuiPaper-root .MuiBox-root .MuiListItem-button{
 text-align: center;
 margin: 20px 0;
 color: white;
}

.menu-sidedrawer .MuiTypography-root{
  font-size: 20px;
  font-weight: 700;
}
.root{
  display: flex;
  box-shadow: none!important;
  background-color: transparent!important;
}

.menu-slider .MuiBackdrop-root{
  background-color: rgb(255 255 255 / 60%);
}

.header-logo{
  width: 20%;
}

.company-logo-height{
  height: 40px;
}

.company-group-logo{
  width: 15vw;
  left: 50%;
  position: relative;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 5vh 0;
}


.builtby-img{
  width: 60vw;
  margin: auto;
  -webkit-filter: brightness(0%) invert(1);
          filter: brightness(0%) invert(1);
}

@media only screen and (max-width: 600px) {
  .header-logo {
    width: 150px
  }

  .company-group-logo{
    width: 40vw;
  }
}
.footer {
  padding: 20px;
  padding-bottom: 0px;
  padding-top: 600px;
  background-color: #f1f1f3;
  background-color: #564181;
  background-color: #685e7c;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 1;
  display: block;
}

.footer-container-main {
  padding: 40px 50px;
}
.footer-container {
  padding: 25px 50px;
}

.footer-cta {
  padding-bottom: 50px;
}

.footer-heading {
  color: white;
  font-size: 2.125rem;
  line-height: 1.76;
  letter-spacing: -0.34px;
}

.footer-subheading {
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  color: rgba(255, 255, 255, 0.432)!important;
}

.MuiOutlinedInput-root {
  position: initial !important;
}
.subscribe {
  color: rgb(158, 158, 158);
  background-color: white;
  border-radius: 10px;
  border: none !important;
  padding: 5px !important;
  outline: none !important;
  position: initial !important;
  margin-right: 150px !important;
  box-shadow: 0 13px 16px rgb(87 65 129 / 10%);
}

.subscribe_button {
  background-color: rgba(87, 65, 129, 0.4) !important;
  color: white !important;
}

.subscribe_button:hover {
  background-color: rgba(87, 65, 129, 0.8) !important;
  color: rgba(255, 255, 255, 0.397) !important;
}

.footer-page-links {
  color: rgb(241, 241, 241) !important;
  text-decoration: none !important;
}
.footer-pages {
  margin-bottom: 15px !important;
}

.widget-title {
  color: rgba(255, 255, 255, 0.432);
  font-weight: 700;
}

.footer-logo {
  margin-top: -20px;
  height: 50px;
  margin-bottom: 30px;
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.footer-builtby {
  height: 35px;
  -webkit-filter: brightness(0%) invert(1);
          filter: brightness(0%) invert(1);
}

.built-by-image {
  text-align: right;
  padding-right: 80px !important;
}

.condition-list {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  hr {
    margin: 5px !important;
    width: 80vw !important;
    text-align: center;
  }
  .footer {
    padding: 0px;
    padding-top: 600px;

    padding-bottom: 20px;
  }

  .footer-heading {
    line-height: 1.3;
    font-size: 1.5rem;
    padding: 0 30px;
  }
  .footer-subheading {
    line-height: 1.4;
    font-size: 1rem;
  }

  .subscribe {
    margin: 0 !important;
    margin-top: 25px !important;
    padding: 0px!important;

  }

  .footer-container {
    padding: 25px;
    padding-bottom: 0px;
  }
  .footer-container-main {
    padding: 30px 20px!important;
    padding-top: 35px;
    padding-bottom: 0px;
  }

  .footer-container p,
  .footer-container ul,
  .built-by-image {
    text-align: center;
    padding-right: 0 !important;
  }

  .footer-builtby {
    margin-top: 0px;
    width: 200px;
    height: auto;
  }

  .footer-widget {
    margin:  0;
  }

  .footer-logo {
    margin: 10px 0px;
    width: 230px;
    height: auto;
  }
}
.hero-container {
  padding: 0vw 7vw !important;
  background: rgb(232,230,235);
  background-position: 40% 70%;
  background-size: 80vh;
  background-repeat: no-repeat;
  background-image: url(/static/media/idea-logo.7028311e.svg);
  background-image: url(/static/media/idea-logo.7028311e.svg);
  background: linear-gradient(180deg, rgba(232,230,235,1) 0%, rgba(232,230,235,1) 62%, rgba(255,255,255,1) 100%);
  /* background-color: rgb(232, 230, 235); */


}

.hero_heading {
  color: #2b3940;
  font-weight: 700;
  width: 70%;
  font-size: calc(1rem + 1vw);
}

.hero-heading-company {
  color: #2b3940;
  font-weight: 700;
  margin-bottom: 0vw;
  font-size: calc(1rem + 1vw);
  text-align: end!important;
  width: 90%;
  margin-left:auto;
}

.coloured-header {
  color: #564181;
}



.height {
  min-height: 100vh;
  justify-content: center;
  /* display: flex; */
  align-items: center;
}

.hero_search_bar {
  color: black;
  background-color: white;
  border-radius: 10px;
  border: none !important;
  padding: 15px !important;
  outline: none !important;
  box-shadow: 0 13px 16px rgb(87 65 129 / 10%);
}

.hero_search_button {
  background-color: rgba(87, 65, 129, 0.4) !important;
  color: white !important;
}

.hero-button {
  color: white !important;
  /* margin-top: 10px!important; */
  margin-left: auto;
  text-transform: capitalize !important;
  width: 40%;
  background-color: rgba(87, 65, 129, 0.705) !important;
}
.alignment-end{
  justify-content: end;
  text-align: end;
}
.hero_search_button:hover {
  background-color: rgba(87, 65, 129, 0.8) !important;
  color: white !important;
}

.autocomplete_field .MuiAutocomplete-inputRoot {
  padding: 0 !important;
}



@media only screen and (max-width: 600px) {
  .hero_heading {
    margin-top: 20vh;
  }
  .hero_image {
    width: 250px;
    margin-top: 20px !important ;
  }

  .height {
    min-height: 10vh;
    justify-content: center;
    display: flex;
    padding: 20px;
    padding-bottom: 0px;
  }

  .hero_search_bar {
    margin-bottom: 10vh !important;
  }
}



@-webkit-keyframes zoom-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  70% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}



@keyframes zoom-in {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  70% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.jobseeker-image,
.company-image {
  width: 55%;
  height: 100%;
  margin-top: 3vw;
  margin-bottom: 2vw;
  border-radius: 20px;
  -webkit-animation: enter-image 3s ease-out;
          animation: enter-image 3s ease-out;
}



@media only screen and (max-width: 600px) {
  .hero_heading,.hero-heading-company {
    margin-bottom: 0px!important;
    margin-top: 20px!important;

    width: 100%;
  }
  .hero-container{
    min-height: 100vh;
    padding: 50px 20px 50px 10px!important;
  }
  .company-image,.jobseeker-image{
    width: 40vw;
  }
}






/* =========== */
@-webkit-keyframes enter {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
    -webkit-transform: rotate(32deg) translateX(50px);
            transform: rotate(32deg) translateX(50px);
  }

  950% {
    opacity: 1;
    -webkit-transform: rotate(32deg) translateX(0px);
            transform: rotate(32deg) translateX(0px);
    right: 0;
  }
}
@keyframes enter {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
    -webkit-transform: rotate(32deg) translateX(50px);
            transform: rotate(32deg) translateX(50px);
  }

  950% {
    opacity: 1;
    -webkit-transform: rotate(32deg) translateX(0px);
            transform: rotate(32deg) translateX(0px);
    right: 0;
  }
}

.category_container{
    padding: 7vw;
    background-color: white!important;
}

.category_header{
    color: #2b3940;
    font-size: 2.5rem;
    line-height: 1.375;
    letter-spacing: -0.4px;
    font-weight: 700 !important;
}

.category_button{
    margin-top: 15px!important;
    border-color: rgb(57, 43, 85);
    font-size: 13px!important;
    font-weight: 700!important;
    color:rgb(87, 65, 129)!important;
    padding: 10px 15px!important;
}
.flex_button{
    text-align: right;
}
.category_card{
    margin-bottom: 2vw;
    padding: 25px;
    border: 1px grey solid;
    border-radius: 10px;
    transition: all 0.3s;
}

.category_card:hover{
    box-shadow: 20px 20px 40px rgb(175 175 175 / 16%)!important;
}
.category_card_header{
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.66;
    letter-spacing: -0.09px;
    color:#2b3940 !important;
}

.category_icon_holder{
    /* background-color: rgba(0, 0, 255, 0.2); */
    padding: 20px;
    border-radius: 10px;
    /* width: auto!important; */
    display: inline-block;
    margin-bottom:1vw;
}
.category_icon{
  color: white;
  /* font-size: large; */
}
.float-container {
    border: 3px solid #fff;
    padding: 20px;
}

.float-child {
    width: 50%;
    float: left;
    padding: 20px;
    border: 2px solid red;
}  



@media only screen and (max-width: 600px) {
    .category_header,.flex_button{
      margin-top: 50px;
      text-align: center;
  
    }
    .category_container{
      padding:20px!important;
    }
  
    .category_card {
      margin-top: 20px;
      text-align: center;
  
    }
    
  }
.callback-form{
    background-color: rgb(243, 243, 243);
    border-radius: 10px;
    padding: 35px;
    margin: 5px;
}

.callback-form input{
    line-height: 24px;
    font-size: 15px;
    display: block;
    width: 100%;
    margin-bottom: 30px;
    padding: 11px 25px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
}


@media only screen and (max-width: 600px) {

    .callback-form{
        padding: 20px;

    }
}
.content-heading {
  line-height: 1.175;
  letter-spacing: -0.4px;
  font-weight: 600;
  color: #2b3940;
  font-size: calc(1.175rem + 1vw);
}

.content-subheading {
  font-weight: 400;
  color: #2b3940;
  font-size: calc(1.1rem);
}

.content-text {
  padding-right: 6vw !important;
}

.content-height {
  min-height: 80vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.content-tile {
  margin-top: 20px;
  background-color: var(--sandy);
  opacity: 0.6;
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.content-icon {
  border-radius: 10px;
  font-size: 2.125rem;
  line-height: 1.76;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.content-icon-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2b3940;
  margin: 10px 0;
}

.secondary-button,
.MuiButton-endIcon {
  transition: all ease-in-out 0.2s;
}
.secondary-button:hover .MuiButton-endIcon {
  margin-left: 20px;
}

.tile-image {
  width: 100%;
  border-radius: 10px;
  margin-left: auto;
  left: 50px;
  position: relative;
}

.services-content ul {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
}
.services-content ul li:before {
  content: "?";
  color: var(--mellow);
  position: absolute;
  font-weight: 900;
  left: 0;
}

.intro-service {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 40px 0!important;
  padding: 0 20px!important;

}

@media only screen and (max-width: 600px) {
  .primary-button {
    width: auto !important;
  }
  .secondary-button {
    width: auto !important;
  }
  .content-icon {
    border-radius: 10px;
    width: 60px;
    height: 60px;
    padding: 10px;
  }
  .tile-image{
    left: 0px;
  }
  .content-subheading {
    font-size: 15px;
  }
}


/* Company Content */


.about-container {
  background-color: white !important;
}

.about-heading {
  line-height: 1.175;
  letter-spacing: -0.4px;
  font-weight: 600;
  color: #2b3940;
  font-size: calc(1.375rem + 1vw);
}

.about-subheading {
  font-weight: 700;
  line-height: 1.2;
  color: #2b3940;
  font-size: calc(1.25625rem + 0.075vw);
}

.font-size-content{
  font-size: 15px;

}
.about-image {
  border-radius: 20px;
  width: 100% !important;
}

.img-container {
  padding-right: 20% !important;
}

.abs-content {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 70%;
  margin-bottom: 10%;
  margin-right: 40px !important;
  padding: 20px;
  border-radius: 15px !important;
  box-shadow: 17px 26px 99px rgb(114 114 114 / 30%);
}

.check-mark {
  background-color: #facf32 !important;
  border-radius: 50%;
  padding: 5px !important;
  width: 35px !important;
  height: 35px !important;
  color: white;
  margin-right: 15px;
}
.accent-heading {
  font-weight: 700 !important;
  color: #2b3940;
}

.about-content {
  padding: 0 10px !important;
}

.media-about {
  margin-top: 40px;
  display: flex;
}

.media-icon-style {
  background-color: var(--sandy) !important;
  border-radius: 10px;
  padding: 10px !important;
  width: 45px !important;
  height: 45px !important;
  color: white;
  margin-right: 15px;
}

.media-body {
  flex: 1 1;
}

.media-icon {
  padding-top: 0px !important;
}

.primary-button{
  padding: 10px;
  color: #564181;
  width: 30%;
  margin: 40px 0 0 0;
  background-color: rgba(0,0,0,0.02);
}

.secondary-button{
  padding: 10px;
  color: #333136;
  width: 30%;
  margin: 40px 0 0 0;
  text-decoration: underline;
  line-height: 1.2rem;
}



@media only screen and (max-width: 600px) {
  .about-content {
    padding: 20px !important;
  }

  .about-container{
    padding: 50px  0!important;
  }

  .img-container {
    margin-bottom: 20px;
    padding: 20px !important;
  }

  .category_icon_holder {
    margin: 20px 0!important;
  }

  .media-icon-style {
    padding: 5px !important;
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px;
    
  }

  .media-icon {
    padding: 0px !important;
  }

  
  .secondary-button{
    width: 80%!important;
  }
  
}
.spacer{
  margin-bottom: calc(85vh); 
  width: 100vw;
}
@media only screen and (min-width: 1600px) {
  .spacer{
    margin-bottom: calc(35vw ); 
  }
} 


.spacer-lip{
    background-color: white;
    height: 30px;
    border-radius: 0 0 50px 50px;
    box-shadow: 0 40px 30px 30px rgba(0,0,0,0.3);
    z-index: 2;
    position: relative;
}

.spacer-lip-grey{
  background-color: #f7f7f7 !important;
}

@media only screen and (max-width: 600px) {
  .spacer {
    margin-bottom: 85vh;
  }

  .spacer-lip{
    border-radius:  0 0 20px 20px;
  }
  .secondary-button {
    width: auto !important;
  }
}
.testimonial-container{
    background: #f0edf5;
    background: #F5F6FA;

    /* background: #f3fcfd; */

    /* background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(236,234,241,1) 30%, rgba(236,234,241,1) 71%, rgba(255,255,255,1) 100%); */
    min-height: 100vh;
    padding: 50px 0; 
    /* display: flex;
    align-items: center; */
}   
.slick-slide div {
    outline: 0;
    outline: none;
  }
.testimonial-header{
    color: #48474b;
    /* text-align: center; */
    margin-bottom: 40px  !important;
    font-size: 3rem;
    font-weight: 700;
}

.testimonial{
    background-color: white;
    background-color: #564181;
    padding: 15px;
    margin-bottom: 50px;
    border-radius: 10px;
    box-shadow: 0 10px 40px -5px rgba(0,0,0,0.1);
}

.testimonial-content{
    padding: 30px 20px;
    border-radius: 10px;
    background-color: #d9f3f8;
    background-color: white;
    box-shadow: 0 20px 20px -5px rgba(0,0,0,0.3);
    font-weight: 00;
    display: flex;
    text-align: justify;
}

.testimonial-panel{
    border-radius: 35px;
    background: #F5F6FA;
    box-shadow:  8px 8px 16px #e9eaee,
                    -8px -8px 16px #ffffff;
    min-height: 50vh;
    min-width: 90%;
    margin-right: 30px;
    margin-bottom: 50px;
    padding: 10px    
}

.testimonial-border{
    border-radius: 25px;
    min-height: 50vh;
    min-width: 100%;
    margin-bottom: 30px;
    border: 3px solid rgb(205, 205, 205);

}

.client{
    color: #564181;
    font-weight: bold;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    padding: 15px ;
    padding-bottom: 10px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: -50px;
    top: 30px;
    box-shadow: 0 10px 30px -5px rgba(0,0,0,0.15);

}


@media only screen and (max-width: 600px) {
    .client {
        width:-webkit-fit-content;
        width:-moz-fit-content;
        width:fit-content;
        max-width: 90%;
        left: 50%;
        top: 25px;
        position: relative;
        -webkit-transform: translateX(-50%)!important;
                transform: translateX(-50%)!important;
    }

    .testimonial-header{
        margin-bottom: 30px  !important;
        font-size: 2.2rem;
        font-weight: 700;

    }

    .testimonial-container{
        padding: 70px 0;
    }

    .testimonial-panel{
        border-radius: 25px;
        background: #F5F6FA;
        box-shadow:  8px 8px 16px #e9eaee,
                        -8px -8px 16px #ffffff;
        min-height: auto;
        min-width: 90%;
        margin-right: 0px;
        margin-bottom: 50px;
        padding: 0px    
    }

  }
.bk-color {
  background-color: #f7f7f7 !important;
}

.details_card {
  padding: 30px;
  border-radius: 20px;
  margin: 2vw 0 4vw 0;
  box-shadow: 20px 20px 40px rgb(175 175 175 / 20%) !important;

}

.media {
  display: flex;
}

.training_card_headers {
  flex: 1 1;
  margin-left: 20px;
}

.details_apply_button {
  padding: 15px;
  margin-right: 1vw !important;
  background-color: #97979718 !important;
}

.details_applied_button {
  padding: 15px;
  margin-right: 1vw !important;
  background-color: #f7ffe4 !important;
}

.details_save_button {
  color: #9fafb8 !important;
  padding: 10px;
}
.card-btn-group {
  margin: 2vw 0;
}



.back_arrow {
  color: #2b3940;
  background-color: white;
  border-radius: 50%;
  width: 35px !important;
  height: 35px !important;
  font-size: 12px !important;
  padding: 7px;
  margin: 0 30px 0px 50px !important;
  box-shadow: 2px 2px 4px rgb(130 130 130 / 16%);
}

.back_arrow_text {
  color: #6c7c85;
  font-weight: 500 !important;
}


.details_attributes {
  color: #464e52;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
}

.details_icon {
  width: 30px !important;
  height: 30px !important;
  color: white;
  margin-right: 1vw;
  border-radius: 50%;
  padding: 5px;
  background-color: #e2a60083;
  box-shadow: 0 4px 22px 0 rgb(185 135 0 / 30%);
}

.details_card_heading {
  color: #2b3940;
  font-size: 2rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
  font-weight: 700 !important;
}
.single_tag {
  color: #2b3940 !important;
  width: auto;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(13, 110, 253, 0.08) !important;
}

@media only screen and (max-width: 600px) {
  .back_arrow {
    margin: 0 15px 0 7px !important;
  }

  .details_date {
    margin: 25px 0;
  }

  .details_icon {
    margin-right: 20px;
  }

  .details_attributes {
    font-weight: 600;
    font-size: 1rem;
  }
  .details_attributes_labels {
    margin-top: 20px;
  }

  .training_details_attributes {
    padding: 30px 0;
  }

  .back_arrow,
  .back_arrow_text {
    margin-bottom: 20px !important;
  }

  .back_arrow {
    margin-top: 20px !important;
  }
}

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;900&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@200;400;600;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@200;400;600;900&family=Poppins:wght@300;400;500;700&display=swap'); */

:root {
  --brown: rgb(207, 210, 174);
  --blue: #9ed8f7;
  --sandy: #f6ab7a;
  --silver: #d1c1b0;
  --green: #b1c995;
  --greeni: #c0e3e9;
  --orange: #fed689;
  --rose: #fbd9d2;
  --opal: #b7d5d3;
  --mellow: rgb(250, 193, 130);
  --purple: #564181;
  --grey: #6a7075;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
  font-family: "DM Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.MuiButton-text,
.MuiListItemText-root,
.MuiTypography-root {
  font-family: "DM Sans", sans-serif !important;
}

.section-bk-color {
  background-color: white;
}

section {
  z-index: 3;
  position: relative;
  overflow-x: hidden!important;
}

.grecaptcha-badge {
  z-index: 5 !important;
  position: relative;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  /* For tablets: */
  .col-md-1 {
    width: 8.33%;
  }
  .col-md-2 {
    width: 16.66%;
  }
  .col-md-3 {
    width: 50%;
  }
  .col-md-4 {
    width: 90%;
  }
  .col-md-5 {
    width: 80%;
  }
  .col-md-6 {
    width: 90%;
  }
  .col-md-7 {
    width: 90%;
  }
  .col-md-8 {
    width: 90%;
  }
  .col-md-10 {
    width: 83.33%;
  }
  .col-md-12 {
    width: 100%;
  }

  .w-100{width:75%!important}

  .offset-md-1{
    padding-top: 30px;
    /* width: 1%; */
  }
  
  .menu-slider{
    z-index: 20;
    position: relative;
  }
  .footer .col-md-4, .footer .col-md-5 {
    width: 100%;
    text-align: center;
  }
  .footer .col-md-5 .btns {
    margin: auto;
    margin-left: 20%;

  }
  .built-by-image {
    padding-right: 10px !important;
  }

  .tile-image {
    margin-left: 0 !important;
  }
  .about-image {
    margin-bottom: 50px;
  }
  .img-container {
    padding: 0 !important;
  }
  .abs-content {
    margin-bottom: 15%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-header {
  color: #222425;
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  font-weight: 600;
  width: 70%;
  margin: 10vh auto 0vh;
}

::-webkit-scrollbar {
  border-radius: 50px;
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 50px;
  box-shadow: 0 0 6px rgba(87, 65, 129, 0.4);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(87, 65, 129, 0.4);
  border-radius: 50px;
}

@media only screen and (max-width: 600px) {
  html,
  body {
    overflow-x: hidden;
  }

  .hero_search_bar {
    width: 100% !important;
  }

  .MuiMenu-paper {
    left: 50% !important;
  }

  .page-header {
    font-size: 1.7rem !important;
    margin: 20px !important;
    margin-top: 50px !important;

    width: 90% !important;
  }

  .MuiSvgIcon-root {
    /* fill: #574181!important; */
  }
}

