/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;900&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@200;400;600;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@200;400;600;900&family=Poppins:wght@300;400;500;700&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Josefin+Sans:wght@400;500;700&family=Montserrat:wght@200;400;600;900&family=Poppins:wght@300;400;500;700&display=swap");

:root {
  --brown: rgb(207, 210, 174);
  --blue: #9ed8f7;
  --sandy: #f6ab7a;
  --silver: #d1c1b0;
  --green: #b1c995;
  --greeni: #c0e3e9;
  --orange: #fed689;
  --rose: #fbd9d2;
  --opal: #b7d5d3;
  --mellow: rgb(250, 193, 130);
  --purple: #564181;
  --grey: #6a7075;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: "Montserrat", sans-serif; */
  font-family: "DM Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.MuiButton-text,
.MuiListItemText-root,
.MuiTypography-root {
  font-family: "DM Sans", sans-serif !important;
}

.section-bk-color {
  background-color: white;
}

section {
  z-index: 3;
  position: relative;
  overflow-x: hidden!important;
}

.grecaptcha-badge {
  z-index: 5 !important;
  position: relative;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  /* For tablets: */
  .col-md-1 {
    width: 8.33%;
  }
  .col-md-2 {
    width: 16.66%;
  }
  .col-md-3 {
    width: 50%;
  }
  .col-md-4 {
    width: 90%;
  }
  .col-md-5 {
    width: 80%;
  }
  .col-md-6 {
    width: 90%;
  }
  .col-md-7 {
    width: 90%;
  }
  .col-md-8 {
    width: 90%;
  }
  .col-md-10 {
    width: 83.33%;
  }
  .col-md-12 {
    width: 100%;
  }

  .w-100{width:75%!important}

  .offset-md-1{
    padding-top: 30px;
    /* width: 1%; */
  }
  
  .menu-slider{
    z-index: 20;
    position: relative;
  }
  .footer .col-md-4, .footer .col-md-5 {
    width: 100%;
    text-align: center;
  }
  .footer .col-md-5 .btns {
    margin: auto;
    margin-left: 20%;

  }
  .built-by-image {
    padding-right: 10px !important;
  }

  .tile-image {
    margin-left: 0 !important;
  }
  .about-image {
    margin-bottom: 50px;
  }
  .img-container {
    padding: 0 !important;
  }
  .abs-content {
    margin-bottom: 15%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-header {
  color: #222425;
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  font-weight: 600;
  width: 70%;
  margin: 10vh auto 0vh;
}

::-webkit-scrollbar {
  border-radius: 50px;
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 50px;
  box-shadow: 0 0 6px rgba(87, 65, 129, 0.4);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(87, 65, 129, 0.4);
  border-radius: 50px;
}

@media only screen and (max-width: 600px) {
  html,
  body {
    overflow-x: hidden;
  }

  .hero_search_bar {
    width: 100% !important;
  }

  .MuiMenu-paper {
    left: 50% !important;
  }

  .page-header {
    font-size: 1.7rem !important;
    margin: 20px !important;
    margin-top: 50px !important;

    width: 90% !important;
  }

  .MuiSvgIcon-root {
    /* fill: #574181!important; */
  }
}
