.footer {
  padding: 20px;
  padding-bottom: 0px;
  padding-top: 600px;
  background-color: #f1f1f3;
  background-color: #564181;
  background-color: #685e7c;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 1;
  display: block;
}

.footer-container-main {
  padding: 40px 50px;
}
.footer-container {
  padding: 25px 50px;
}

.footer-cta {
  padding-bottom: 50px;
}

.footer-heading {
  color: white;
  font-size: 2.125rem;
  line-height: 1.76;
  letter-spacing: -0.34px;
}

.footer-subheading {
  font-size: 1.125rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
  color: rgba(255, 255, 255, 0.432)!important;
}

.MuiOutlinedInput-root {
  position: initial !important;
}
.subscribe {
  color: rgb(158, 158, 158);
  background-color: white;
  border-radius: 10px;
  border: none !important;
  padding: 5px !important;
  outline: none !important;
  position: initial !important;
  margin-right: 150px !important;
  box-shadow: 0 13px 16px rgb(87 65 129 / 10%);
}

.subscribe_button {
  background-color: rgba(87, 65, 129, 0.4) !important;
  color: white !important;
}

.subscribe_button:hover {
  background-color: rgba(87, 65, 129, 0.8) !important;
  color: rgba(255, 255, 255, 0.397) !important;
}

.footer-page-links {
  color: rgb(241, 241, 241) !important;
  text-decoration: none !important;
}
.footer-pages {
  margin-bottom: 15px !important;
}

.widget-title {
  color: rgba(255, 255, 255, 0.432);
  font-weight: 700;
}

.footer-logo {
  margin-top: -20px;
  height: 50px;
  margin-bottom: 30px;
  filter: brightness(0) invert(1);
}

.footer-builtby {
  height: 35px;
  filter: brightness(0%) invert(1);
}

.built-by-image {
  text-align: right;
  padding-right: 80px !important;
}

.condition-list {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  hr {
    margin: 5px !important;
    width: 80vw !important;
    text-align: center;
  }
  .footer {
    padding: 0px;
    padding-top: 600px;

    padding-bottom: 20px;
  }

  .footer-heading {
    line-height: 1.3;
    font-size: 1.5rem;
    padding: 0 30px;
  }
  .footer-subheading {
    line-height: 1.4;
    font-size: 1rem;
  }

  .subscribe {
    margin: 0 !important;
    margin-top: 25px !important;
    padding: 0px!important;

  }

  .footer-container {
    padding: 25px;
    padding-bottom: 0px;
  }
  .footer-container-main {
    padding: 30px 20px!important;
    padding-top: 35px;
    padding-bottom: 0px;
  }

  .footer-container p,
  .footer-container ul,
  .built-by-image {
    text-align: center;
    padding-right: 0 !important;
  }

  .footer-builtby {
    margin-top: 0px;
    width: 200px;
    height: auto;
  }

  .footer-widget {
    margin:  0;
  }

  .footer-logo {
    margin: 10px 0px;
    width: 230px;
    height: auto;
  }
}