.bk-color {
  background-color: #f7f7f7 !important;
}

.details_card {
  padding: 30px;
  border-radius: 20px;
  margin: 2vw 0 4vw 0;
  box-shadow: 20px 20px 40px rgb(175 175 175 / 20%) !important;

}

.media {
  display: flex;
}

.training_card_headers {
  flex: 1;
  margin-left: 20px;
}

.details_apply_button {
  padding: 15px;
  margin-right: 1vw !important;
  background-color: #97979718 !important;
}

.details_applied_button {
  padding: 15px;
  margin-right: 1vw !important;
  background-color: #f7ffe4 !important;
}

.details_save_button {
  color: #9fafb8 !important;
  padding: 10px;
}
.card-btn-group {
  margin: 2vw 0;
}



.back_arrow {
  color: #2b3940;
  background-color: white;
  border-radius: 50%;
  width: 35px !important;
  height: 35px !important;
  font-size: 12px !important;
  padding: 7px;
  margin: 0 30px 0px 50px !important;
  box-shadow: 2px 2px 4px rgb(130 130 130 / 16%);
}

.back_arrow_text {
  color: #6c7c85;
  font-weight: 500 !important;
}


.details_attributes {
  color: #464e52;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.66;
  letter-spacing: -0.09px;
}

.details_icon {
  width: 30px !important;
  height: 30px !important;
  color: white;
  margin-right: 1vw;
  border-radius: 50%;
  padding: 5px;
  background-color: #e2a60083;
  box-shadow: 0 4px 22px 0 rgb(185 135 0 / 30%);
}

.details_card_heading {
  color: #2b3940;
  font-size: 2rem;
  line-height: 1.375;
  letter-spacing: -0.4px;
  font-weight: 700 !important;
}
.single_tag {
  color: #2b3940 !important;
  width: auto;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(13, 110, 253, 0.08) !important;
}

@media only screen and (max-width: 600px) {
  .back_arrow {
    margin: 0 15px 0 7px !important;
  }

  .details_date {
    margin: 25px 0;
  }

  .details_icon {
    margin-right: 20px;
  }

  .details_attributes {
    font-weight: 600;
    font-size: 1rem;
  }
  .details_attributes_labels {
    margin-top: 20px;
  }

  .training_details_attributes {
    padding: 30px 0;
  }

  .back_arrow,
  .back_arrow_text {
    margin-bottom: 20px !important;
  }

  .back_arrow {
    margin-top: 20px !important;
  }
}
