.testimonial-container{
    background: #f0edf5;
    background: #F5F6FA;

    /* background: #f3fcfd; */

    /* background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(236,234,241,1) 30%, rgba(236,234,241,1) 71%, rgba(255,255,255,1) 100%); */
    min-height: 100vh;
    padding: 50px 0; 
    /* display: flex;
    align-items: center; */
}   
.slick-slide div {
    outline: 0;
    outline: none;
  }
.testimonial-header{
    color: #48474b;
    /* text-align: center; */
    margin-bottom: 40px  !important;
    font-size: 3rem;
    font-weight: 700;
}

.testimonial{
    background-color: white;
    background-color: #564181;
    padding: 15px;
    margin-bottom: 50px;
    border-radius: 10px;
    box-shadow: 0 10px 40px -5px rgba(0,0,0,0.1);
}

.testimonial-content{
    padding: 30px 20px;
    border-radius: 10px;
    background-color: #d9f3f8;
    background-color: white;
    box-shadow: 0 20px 20px -5px rgba(0,0,0,0.3);
    font-weight: 00;
    display: flex;
    text-align: justify;
}

.testimonial-panel{
    border-radius: 35px;
    background: #F5F6FA;
    box-shadow:  8px 8px 16px #e9eaee,
                    -8px -8px 16px #ffffff;
    min-height: 50vh;
    min-width: 90%;
    margin-right: 30px;
    margin-bottom: 50px;
    padding: 10px    
}

.testimonial-border{
    border-radius: 25px;
    min-height: 50vh;
    min-width: 100%;
    margin-bottom: 30px;
    border: 3px solid rgb(205, 205, 205);

}

.client{
    color: #564181;
    font-weight: bold;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    padding: 15px ;
    padding-bottom: 10px;
    position: relative;
    width: fit-content;
    left: -50px;
    top: 30px;
    box-shadow: 0 10px 30px -5px rgba(0,0,0,0.15);

}


@media only screen and (max-width: 600px) {
    .client {
        width:fit-content;
        max-width: 90%;
        left: 50%;
        top: 25px;
        position: relative;
        transform: translateX(-50%)!important;
    }

    .testimonial-header{
        margin-bottom: 30px  !important;
        font-size: 2.2rem;
        font-weight: 700;

    }

    .testimonial-container{
        padding: 70px 0;
    }

    .testimonial-panel{
        border-radius: 25px;
        background: #F5F6FA;
        box-shadow:  8px 8px 16px #e9eaee,
                        -8px -8px 16px #ffffff;
        min-height: auto;
        min-width: 90%;
        margin-right: 0px;
        margin-bottom: 50px;
        padding: 0px    
    }

  }