.category_container{
    padding: 7vw;
    background-color: white!important;
}

.category_header{
    color: #2b3940;
    font-size: 2.5rem;
    line-height: 1.375;
    letter-spacing: -0.4px;
    font-weight: 700 !important;
}

.category_button{
    margin-top: 15px!important;
    border-color: rgb(57, 43, 85);
    font-size: 13px!important;
    font-weight: 700!important;
    color:rgb(87, 65, 129)!important;
    padding: 10px 15px!important;
}
.flex_button{
    text-align: right;
}
.category_card{
    margin-bottom: 2vw;
    padding: 25px;
    border: 1px grey solid;
    border-radius: 10px;
    transition: all 0.3s;
}

.category_card:hover{
    box-shadow: 20px 20px 40px rgb(175 175 175 / 16%)!important;
}
.category_card_header{
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.66;
    letter-spacing: -0.09px;
    color:#2b3940 !important;
}

.category_icon_holder{
    /* background-color: rgba(0, 0, 255, 0.2); */
    padding: 20px;
    border-radius: 10px;
    /* width: auto!important; */
    display: inline-block;
    margin-bottom:1vw;
}
.category_icon{
  color: white;
  /* font-size: large; */
}
.float-container {
    border: 3px solid #fff;
    padding: 20px;
}

.float-child {
    width: 50%;
    float: left;
    padding: 20px;
    border: 2px solid red;
}  



@media only screen and (max-width: 600px) {
    .category_header,.flex_button{
      margin-top: 50px;
      text-align: center;
  
    }
    .category_container{
      padding:20px!important;
    }
  
    .category_card {
      margin-top: 20px;
      text-align: center;
  
    }
    
  }