.menu-sidedrawer .MuiPaper-root {
  height: calc(100%) !important;
  width: calc(100%) !important;
}

.menu-sidedrawer  .MuiPaper-root{
  background-color: rgb(117, 102, 133);
}

.menu-sidedrawer .MuiPaper-root .MuiBox-root{
  margin:0 auto; 
}

.menuIcon{
  color: #564181
}

.MuiDrawer-root .MuiPaper-root .MuiBox-root .MuiListItem-button{
 text-align: center;
 margin: 20px 0;
 color: white;
}

.menu-sidedrawer .MuiTypography-root{
  font-size: 20px;
  font-weight: 700;
}
.root{
  display: flex;
  box-shadow: none!important;
  background-color: transparent!important;
}

.menu-slider .MuiBackdrop-root{
  background-color: rgb(255 255 255 / 60%);
}

.header-logo{
  width: 20%;
}

.company-logo-height{
  height: 40px;
}

.company-group-logo{
  width: 15vw;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  margin: 5vh 0;
}


.builtby-img{
  width: 60vw;
  margin: auto;
  filter: brightness(0%) invert(1);
}

@media only screen and (max-width: 600px) {
  .header-logo {
    width: 150px
  }

  .company-group-logo{
    width: 40vw;
  }
}