.spacer{
  margin-bottom: calc(85vh); 
  width: 100vw;
}
@media only screen and (min-width: 1600px) {
  .spacer{
    margin-bottom: calc(35vw ); 
  }
} 


.spacer-lip{
    background-color: white;
    height: 30px;
    border-radius: 0 0 50px 50px;
    box-shadow: 0 40px 30px 30px rgba(0,0,0,0.3);
    z-index: 2;
    position: relative;
}

.spacer-lip-grey{
  background-color: #f7f7f7 !important;
}

@media only screen and (max-width: 600px) {
  .spacer {
    margin-bottom: 85vh;
  }

  .spacer-lip{
    border-radius:  0 0 20px 20px;
  }
  .secondary-button {
    width: auto !important;
  }
}