.hero-container {
  padding: 0vw 7vw !important;
  background: rgb(232,230,235);
  background-position: 40% 70%;
  background-size: 80vh;
  background-repeat: no-repeat;
  background-image: url("../../../components/assets/idea-logo.svg");
  background-image: url("../../../components/assets/idea-logo.svg") ;
  background: linear-gradient(180deg, rgba(232,230,235,1) 0%, rgba(232,230,235,1) 62%, rgba(255,255,255,1) 100%);
  /* background-color: rgb(232, 230, 235); */


}

.hero_heading {
  color: #2b3940;
  font-weight: 700;
  width: 70%;
  font-size: calc(1rem + 1vw);
}

.hero-heading-company {
  color: #2b3940;
  font-weight: 700;
  margin-bottom: 0vw;
  font-size: calc(1rem + 1vw);
  text-align: end!important;
  width: 90%;
  margin-left:auto;
}

.coloured-header {
  color: #564181;
}



.height {
  min-height: 100vh;
  justify-content: center;
  /* display: flex; */
  align-items: center;
}

.hero_search_bar {
  color: black;
  background-color: white;
  border-radius: 10px;
  border: none !important;
  padding: 15px !important;
  outline: none !important;
  box-shadow: 0 13px 16px rgb(87 65 129 / 10%);
}

.hero_search_button {
  background-color: rgba(87, 65, 129, 0.4) !important;
  color: white !important;
}

.hero-button {
  color: white !important;
  /* margin-top: 10px!important; */
  margin-left: auto;
  text-transform: capitalize !important;
  width: 40%;
  background-color: rgba(87, 65, 129, 0.705) !important;
}
.alignment-end{
  justify-content: end;
  text-align: end;
}
.hero_search_button:hover {
  background-color: rgba(87, 65, 129, 0.8) !important;
  color: white !important;
}

.autocomplete_field .MuiAutocomplete-inputRoot {
  padding: 0 !important;
}



@media only screen and (max-width: 600px) {
  .hero_heading {
    margin-top: 20vh;
  }
  .hero_image {
    width: 250px;
    margin-top: 20px !important ;
  }

  .height {
    min-height: 10vh;
    justify-content: center;
    display: flex;
    padding: 20px;
    padding-bottom: 0px;
  }

  .hero_search_bar {
    margin-bottom: 10vh !important;
  }
}



@keyframes zoom-in {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.jobseeker-image,
.company-image {
  width: 55%;
  height: 100%;
  margin-top: 3vw;
  margin-bottom: 2vw;
  border-radius: 20px;
  animation: enter-image 3s ease-out;
}



@media only screen and (max-width: 600px) {
  .hero_heading,.hero-heading-company {
    margin-bottom: 0px!important;
    margin-top: 20px!important;

    width: 100%;
  }
  .hero-container{
    min-height: 100vh;
    padding: 50px 20px 50px 10px!important;
  }
  .company-image,.jobseeker-image{
    width: 40vw;
  }
}






/* =========== */
@keyframes enter {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
    transform: rotate(32deg) translateX(50px);
  }

  950% {
    opacity: 1;
    transform: rotate(32deg) translateX(0px);
    right: 0;
  }
}
