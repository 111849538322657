.callback-form{
    background-color: rgb(243, 243, 243);
    border-radius: 10px;
    padding: 35px;
    margin: 5px;
}

.callback-form input{
    line-height: 24px;
    font-size: 15px;
    display: block;
    width: 100%;
    margin-bottom: 30px;
    padding: 11px 25px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
}


@media only screen and (max-width: 600px) {

    .callback-form{
        padding: 20px;

    }
}