.content-heading {
  line-height: 1.175;
  letter-spacing: -0.4px;
  font-weight: 600;
  color: #2b3940;
  font-size: calc(1.175rem + 1vw);
}

.content-subheading {
  font-weight: 400;
  color: #2b3940;
  font-size: calc(1.1rem);
}

.content-text {
  padding-right: 6vw !important;
}

.content-height {
  min-height: 80vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.content-tile {
  margin-top: 20px;
  background-color: var(--sandy);
  opacity: 0.6;
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.content-icon {
  border-radius: 10px;
  font-size: 2.125rem;
  line-height: 1.76;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.content-icon-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2b3940;
  margin: 10px 0;
}

.secondary-button,
.MuiButton-endIcon {
  transition: all ease-in-out 0.2s;
}
.secondary-button:hover .MuiButton-endIcon {
  margin-left: 20px;
}

.tile-image {
  width: 100%;
  border-radius: 10px;
  margin-left: auto;
  left: 50px;
  position: relative;
}

.services-content ul {
  position: relative;
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
}
.services-content ul li:before {
  content: "?";
  color: var(--mellow);
  position: absolute;
  font-weight: 900;
  left: 0;
}

.intro-service {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 40px 0!important;
  padding: 0 20px!important;

}

@media only screen and (max-width: 600px) {
  .primary-button {
    width: auto !important;
  }
  .secondary-button {
    width: auto !important;
  }
  .content-icon {
    border-radius: 10px;
    width: 60px;
    height: 60px;
    padding: 10px;
  }
  .tile-image{
    left: 0px;
  }
  .content-subheading {
    font-size: 15px;
  }
}


/* Company Content */


.about-container {
  background-color: white !important;
}

.about-heading {
  line-height: 1.175;
  letter-spacing: -0.4px;
  font-weight: 600;
  color: #2b3940;
  font-size: calc(1.375rem + 1vw);
}

.about-subheading {
  font-weight: 700;
  line-height: 1.2;
  color: #2b3940;
  font-size: calc(1.25625rem + 0.075vw);
}

.font-size-content{
  font-size: 15px;

}
.about-image {
  border-radius: 20px;
  width: 100% !important;
}

.img-container {
  padding-right: 20% !important;
}

.abs-content {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 70%;
  margin-bottom: 10%;
  margin-right: 40px !important;
  padding: 20px;
  border-radius: 15px !important;
  box-shadow: 17px 26px 99px rgb(114 114 114 / 30%);
}

.check-mark {
  background-color: #facf32 !important;
  border-radius: 50%;
  padding: 5px !important;
  width: 35px !important;
  height: 35px !important;
  color: white;
  margin-right: 15px;
}
.accent-heading {
  font-weight: 700 !important;
  color: #2b3940;
}

.about-content {
  padding: 0 10px !important;
}

.media-about {
  margin-top: 40px;
  display: flex;
}

.media-icon-style {
  background-color: var(--sandy) !important;
  border-radius: 10px;
  padding: 10px !important;
  width: 45px !important;
  height: 45px !important;
  color: white;
  margin-right: 15px;
}

.media-body {
  flex: 1;
}

.media-icon {
  padding-top: 0px !important;
}

.primary-button{
  padding: 10px;
  color: #564181;
  width: 30%;
  margin: 40px 0 0 0;
  background-color: rgba(0,0,0,0.02);
}

.secondary-button{
  padding: 10px;
  color: #333136;
  width: 30%;
  margin: 40px 0 0 0;
  text-decoration: underline;
  line-height: 1.2rem;
}



@media only screen and (max-width: 600px) {
  .about-content {
    padding: 20px !important;
  }

  .about-container{
    padding: 50px  0!important;
  }

  .img-container {
    margin-bottom: 20px;
    padding: 20px !important;
  }

  .category_icon_holder {
    margin: 20px 0!important;
  }

  .media-icon-style {
    padding: 5px !important;
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px;
    
  }

  .media-icon {
    padding: 0px !important;
  }

  
  .secondary-button{
    width: 80%!important;
  }
  
}